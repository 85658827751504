<template>
    <div>
      <Header />
      <slot />
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>